body.noscroll {
  overflow-y: hidden;
}

a {
  text-decoration: none;
}

iframe {
  display: none;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1 1 auto;
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  src: local("Roboto"), url(assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(assets/fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
}
